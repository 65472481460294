.wapper{
  display: flex;
  flex-direction: row;
  margin: 16px;
  align-items: center;
}

.infoContainer {
  width: 100%;
  margin-right: 6px;
  font-family: "Google Sans";
  font-size: 13px;
  letter-spacing: 0.0075em;
  line-height: 1.5;
  .isBold {
    font-weight: 600;
  }
}

.iconContainer{
  height: 36px !important;
  width: 36px !important;
}