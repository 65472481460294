.foodBackground {
  width: 100%;
  background-image: url("/img/food1.jpg");
  background-color: black;
  height: 100vh;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-animation: food1 1s ; /* Chrome, Safari, Opera */
  animation: food1 1s ;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes food1 {
  from {opacity: 0.2;}
  to {opacity: 1;}
}

/* Standard syntax */
@keyframes food1 {
  from {opacity: 0.2;}
  to {opacity: 1;}
}

.backgroundOpaque {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  display : table;
}