.transformCardWarning {
  box-shadow: none !important;
  background-color: transparent !important;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.centerText {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
  text-align: center;
}

.cardImgPosition {
  margin-bottom: 40px;
  width: 60%;
}

.cardExplication {
  color: white;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px !important;
}

.cardExplication2 {
  color: white;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px !important;
}

.cardExplicationBottom {
  color: white;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px !important;
}

.cardExplicationJoint {
  color: white;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px !important;
}

.cardWelcome {
  color: white;
  font-size: 20px !important;
  font-family: "Google Sans" !important;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700 !important;
}

.btnArea {
  margin-bottom: 26px;
}

.hidden{
  display:none;
}
.btnFacebook {
  box-shadow: none !important;
  border-radius: 8px !important;
  height: 50px !important;
  width: 300px !important;
  background-color: #1977F3 !important;
  color: white !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 14px !important;
  padding: 6px 8px !important;
}

.btnGoogle {
  box-shadow: none !important;
  border-radius: 8px !important;
  height: 50px !important;
  width: 300px !important;
  background-color: white !important;
  color: #5f6368 !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 14px !important;
  padding: 6px 8px !important;
}

.btnNormal {
  box-shadow: none !important;
  border-radius: 8px !important;
  height: 50px !important;
  width: 300px !important;
  background-color: #d71a36 !important;
  color: white !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 14px !important;
  padding: 6px 8px !important;
}

.loginLogoService {
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px !important;
}

.btnChangeStep {
  height: 40px !important;
  background-color: white !important;
  color: #5f6368 !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  text-transform: none !important;
  border-radius: 20px !important;
}

.formPosition {
  margin-bottom: 30px;
  width: 300px !important;
  border-radius: 8px !important;
  -webkit-backdrop-filter: blur(8px) !important;
  backdrop-filter: blur(8px) !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.formContainer {
  width: 300px;
  margin: auto;
}

.iconPos {
  position: absolute;
  color: white;
  top: 16px;
  left: 10px;
  width: 30px !important;
  height: 30px !important;
}
.Error{
  color:#f7435e;
}