.specialFab {
  width: 40px !important;
  height: 40px !important;
  //background-color: transparent !important;
  background-color: #F8F8FA !important;
  //border: 2px solid #b4b6be !important;
  box-shadow: none !important;
  .iconColor {
    color: #b4b6be;
  }
}