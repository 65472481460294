.modalContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 8px;
}

.modalBody {
  width: 100%;
  max-width: 500px;
  outline: none;
  border: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  top: 32px;
  right: 50%;
}

.btnFlat {
  box-shadow: none !important;
  text-transform: none !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  width: 100%;
  border-radius: 6px !important;
  background-color: #d71a36 !important;
}

.table100 {
  width: 100%;
}

.userDataContainer {
  padding: 16px;
}

.avatarArea {
  width: 60px;
}

.btnEditArea {
  width: 52px;
  text-align: right;
}

.avatarRounder {
  background: #c0bfbf;
  border-radius: 25px;
  color: rgba(255, 255, 255, .95);
  line-height: 1.25;
  margin: 0;
  position: relative;
  vertical-align: middle;
  float: left;
  font-size: 20px !important;
  height: 50px !important;
  width: 50px !important;
  box-shadow: 0 0.25rem 0.8rem rgba(48,55,66,.15) !important;
}

.avatarRounder[data-initial]::before {
  color: currentColor;
  content: attr(data-initial);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-family: "Google Sans";
}

.avatarRounder img {
  border-radius: 25px;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
  object-fit: cover;
}


.btnRounderBorder {
  box-shadow: none !important;
  // border: 2px solid rgba(102, 102, 102, 0.7) !important;
  // background-color: white !important;
  background-color: #F8F8FA !important;
  width: 36px !important;
  height: 36px !important;
}

.markText {
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  font-size: 14px;
}

.normalText {
  font-family: 'Google Sans' !important;
  color: #666;
  font-size: 14px;
}

.headerModal {
  font-family: 'Google Sans' !important;
  color: #666;
  padding-left: 12px;
  padding-right: 12px;
}

.divBorders {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.divCenter {
  text-align: center;
}

.btnFlatBorders {
  font-family: 'Google Sans' !important;
  color: #666 !important;
  text-transform: none !important;
  font-size: 15px !important;
  border-radius: 20px !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  background-color: white !important;
  height: 40px !important;
  border-color: #666 !important;
  border: 2px solid !important;
  box-shadow: none !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
  font-weight: 500 !important;
}