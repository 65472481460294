
.btnRounderFlat {
  color: white !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.newHeader {
  box-shadow: none !important;
  height: 56px !important;
  background-color: #d71a36 !important;
}

.newHeaderWeb {
  box-shadow: none !important;
  height: 64px !important;
  background-color: #d71a36 !important;
}

.navbarSection {
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  -ms-flex: 1 0 0;
  flex: 1 0 0; 
  -ms-flex-align: center;
}

.navbarSection:not(:first-child):last-child {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.navbarCenter {
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; 
  -ms-flex-align: center;
  margin-top: 6px;
}

.newHeaderLong {
  height: 180px;
  position: relative !important;
}

.titleText {
  color: white !important;
  font-family: 'Google Sans' !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 12;
  top: 56px;
}

.centerHeader {
	text-align: center;
}

.mainHeader {
  width: 100%;
}

.locationArea {
  width: 28px;
  height: 24px;
  color: white !important;
}

.tableCenter {
  margin:auto;
}

.headerInicials {
  background: #c0bfbf;
  color: rgba(255, 255, 255, .95);
  line-height: 1.25;
  margin: 0;
  position: relative;
  vertical-align: middle;
  float: left;
  font-size: 38px !important;
  height: 180px !important;
  width: 100% !important;
}

.headerInicials[data-initial]::before {
  color: currentColor;
  content: attr(data-initial);
  left: 50%;
  position: absolute;
  top: 70%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-family: "Google Sans";
}

.headerInicials img {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
  object-fit: cover;
}

.opaque {
  width: 100%;
  position: absolute;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.7) 30%,rgba(255,255,255,0) 100%);
  left: 0px;
  z-index: 10;
  height: 110px;
  top: 0;
}

.specialToolbar {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.specialToolbarImage {
  position: absolute !important;
  z-index: 11;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
}

.toolbarLogo {
  width: 100px !important;
  position: relative !important;
  top: -3px !important;
}

.acercaBtn {
  font-family: "Google Sans" !important;
  text-transform: none !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 6px 12px !important;
  border: 1px solid #c0bfbf !important;
}

.cardAvatar {
  width: 78px;
  height: 78px;
  position: absolute;
  margin-top: -39px;
  z-index: 1200;
  left: 14px;
}

.avatarRounder {
  background: #F8F8FA;
  border-radius: 39px;
  color: #ACACAD;
  line-height: 1.25;
  margin: 0;
  position: relative;
  vertical-align: middle;
  float: left;
  font-size: 32px !important;
  height: 78px !important;
  width: 78px !important;
}

.avatarRounder[data-initial]::before {
  color: currentColor;
  content: attr(data-initial);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-family: "Google Sans";
}

.avatarRounder img {
  border-radius: 39px;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
  object-fit: cover;
}

.drawerBtnArea {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding-right: 12px;
}

.nameArea {
  line-height: 1.2 !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  color: #424242 !important;
  margin-top: 12px;
  margin-bottom: 0px;
  margin-left: 100px;
  margin-right: 16px;
  font-size: 22px;
}

.btnOpenDrawer {
  box-shadow: none !important;
  display: inline-block !important;
  //color: #8D8F93 !important;
  //background: white !important;
  color: #eba613 !important;
  background-color: rgba(235, 166, 19, 0.2) !important;
  height: 30px !important;
  line-height: 30px !important;
  padding: 0 10px 0 10px !important;
  font-size: 14px !important;
  text-decoration: none !important;
  border-radius: 15px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  margin-top: 10px !important;
}

.iconArrow {
  width: 16px !important;
  height: 16px !important;
  position: relative;
  top: 4px;
  left: 3px;
}

.useFont {
  font-family: "Google Sans" !important;
}