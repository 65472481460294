.superFabMozo{
  position: fixed !important;
  bottom: 16px;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d71a36;
  border-radius: 50px;
  font-family: "Google Sans";
  font-size: 14px;
  color: #ffffff;
}

.cardActionsBtns {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 0px !important;
  padding-bottom: 12px !important;
}

.transformCard {
  box-shadow: 0 0.16rem 1rem rgba(48,55,66,.15) !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

.transformCardSpecial {
  box-shadow: 0 0.16rem 1rem rgba(48,55,66,.15) !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

.specialContent {
  padding-top: 12px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 8px !important;
}

.cardTitleWapper{
  width: 100%;
  height: auto;
  display: flex;
}

.cardAvatar {
  width: 104px;
  height: 96px;
  padding-left: 8px;
}

.avatarRounder {
  background: #c0bfbf;
  border-radius: 10px;
  color: rgba(255, 255, 255, .95);
  line-height: 1.25;
  margin: 0;
  position: relative;
  vertical-align: middle;
  float: left;
  font-size: 38px !important;
  height: 96px !important;
  width: 96px !important;
  box-shadow: 0 0.25rem 0.8rem rgba(48,55,66,.15) !important;
}

.avatarRounder[data-initial]::before {
  color: currentColor;
  content: attr(data-initial);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-family: "Google Sans";
}

.avatarRounder img {
  border-radius: 10px;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
  object-fit: cover;
}

.cardTitle {
  width: calc(100% - 96px);
  align-items: center;
}

.cardTitleExtended {
  width: 100%;
  align-items: center;
}

.cardDescription {
  margin-top: 2px;
  height: 70px;
  overflow: hidden;
  position: relative;
  font-size: 14px !important;
  font-family: "Google Sans" !important;
  line-height: 1.3 !important;
  letter-spacing: 0.01071em !important;
  color: #565B6B;
}

.cardTitleText {
  line-height: 1.2 !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  color: #424242 !important;
}

.onlyTitle {
  display: flex;
}

.btnFlat {
  box-shadow: none !important;
  color:#d71a36 !important;
  background-color: white !important;
  border: 1.5px solid #d71a36 !important;
  text-transform: none !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border-radius: 6px !important;
}

.btnRounderFlat {
  box-shadow: none !important;
  background-color: #9e0011 !important;
  color: white !important;
}

.btnRounderFlatNoEvents {
  box-shadow: none !important;
  pointer-events: none;
}

.currencyColor {
  color: #02bf77 !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.whiteEllipsis {
  position: absolute;
  height: 20px;
  right: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0.6) 1%, white 100%);
}

.fabBtn {
  position: fixed !important;
  bottom: 14px;
  z-index: 3;
  right: 14px;
  height: 60px !important;
  width: 60px !important;
  color:white !important;
  background-color: #9e0011 !important;
}

.fabBtnWhats {
  position: fixed !important;
  bottom: 14px;
  z-index: 3;
  right: 14px;
  height: 60px !important;
  width: 60px !important;
  color:white !important;
  background-color: #0dc143!important;
}
.fabBtnCallCount {
  position: fixed !important;
  bottom: 0;
  left:0;
  z-index: 3;
  right: 14px;
  height: 60px !important;
  width: 60px !important;
  color:white !important;
  
}
.fabBtnCallMozo {
  position: fixed !important;
  bottom: 70px;
  left:0;
  z-index: 3;
  right: 14px;
  height: 60px !important;
  width: 60px !important;
  color:white !important;
  
}

.iconBig {
  font-size: 32px !important;
}

.seachtText{
  width: 100%;
  border-radius: 40px;
  background-color: #F8F8FA;
  border: none !important;
  input{
    padding: 10px 44px!important;
    border: none !important;
  }
}

.titleCat{
  color: #d71a36 !important;
  font-family: "Google Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  display: block;
  width: 100%;
  margin: 16px;
  position: relative !important;
}

.titleCat:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 1px;
  width: calc(100% - 46px);
  border-bottom: .1rem solid rgba(215, 26, 54, 0.6);
}

.searchSpecialMargins {
  margin-right: 6px;
  margin-left: 6px;
  height: 60px;
}

.btnRounderFlatSearch {
  color:#5f6368 !important;
  background-color: #F8F8FA !important;
  box-shadow: none !important;
  width: 39px !important;
  height: 39px !important;
  position: relative !important;
  top: -47px;
}

.transformCardWarning {
  box-shadow: none !important;
  background-color: transparent !important;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
  text-align: center;
}

.cardImgTop {
  width: 100%;
  max-width: 500px;
}

.centerText {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
  text-align: center;
}

.btnRounderToTop {
  //background-color: #F8F8FA !important;
  background-color: white !important;
  position: absolute !important;
  color: #d71a36 !important;
  box-shadow: none !important;
  right: 0px;
}

.divHorizontalScroll {
  width: 100%;
  height: 36px;
  margin-top: 12px;
  padding-right: 10px;
  padding-left: 10px;
  position: relative !important;
}

.divHorizontalWeb {
  width: 100%;
  min-height: 36px;
  margin-top: 12px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
}

.scrollingWrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  text-align: center;
  -webkit-overflow-scrolling: touch !important;
}

.leftFade {
  width: 14px;
  height: 36px;
  top: 0px;
  left: 10px;
  //background: linear-gradient(to left, rgba(248, 248, 250, 0) 1%, rgba(248, 248, 250, 0) 4%, rgba(248, 248, 250, 1) 100%);
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0) 4%, rgba(255, 255, 255, 1) 100%);
  position: absolute !important;
  z-index: 999;
}

.rightFade {
  width: 14px;
  height: 36px;
  top: 0px;
  right: 10px;
  //background: linear-gradient(to right, rgba(248, 248, 250, 0) 1%, rgba(248, 248, 250, 0) 4%, rgba(248, 248, 250, 1) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0) 4%, rgba(255, 255, 255, 1) 100%);
  position: absolute !important;
  z-index: 999;
}

.spacerBottom{
  margin-bottom: 40px;
}
