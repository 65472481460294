.centerText {
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

.loaderColor {
  color: #d71a36 !important;
}

.loaderColorAlternative {
  color: #ffffff !important;
}

.LoaderText {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: #d71a36 !important;
  font-family: "Google Sans";
  font-size: 16px;
  font-weight: 600;
}

.LoaderTextAlternative {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: #ffffff !important;
  font-family: "Google Sans";
  font-size: 16px;
  font-weight: 600;
}

.topSpace {
  padding-top: 20px !important;
}

.noTopSpace {
  padding-top: 0px !important;
}