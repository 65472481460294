.contentDrawer{
  width: 100%;
  height: 100%;
  position: relative;
}
.papper{
  height: 90%!important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
 }
//  .closeBtn{
//   width: 32px !important;
//   height: 32px !important;
//   position: absolute !important;
//   min-height: 32px !important;
//   z-index: 9;
//   right: 12px;
//   top: 12px;
//   box-shadow: none !important;
//   background-color: #F8F8FA !important;
//  }

.btnRounderBorder {
  box-shadow: none !important;
  //border: 2px solid rgba(102, 102, 102, 0.7) !important;
  background-color: #F8F8FA !important;
  position: absolute !important;
  z-index: 9;
  right: 12px;
  top: 12px;
  width: 36px !important;
  height: 36px !important;
}

.limitSpace {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 10px;
}