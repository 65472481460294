.buttonNoShadow {
  box-shadow: none !important;
  display: inline-block !important;
  color: #d71a36 !important;
  background: rgba(215, 26, 54, 0.2) !important;
  height: 30px !important;
  line-height: 30px !important;
  margin: 0 4px 6px 0 !important;
  padding: 0 10px 0 10px !important;
  font-size: 14px !important;
  text-decoration: none !important;
  border-radius: 15px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}