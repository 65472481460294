.transformCardWarning {
  box-shadow: none !important;
  background-color: transparent !important;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.cardImgPosition {
  //margin-top: 80px;
  margin-bottom: 40px;
  width: 60%;
}

.centerText {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
  text-align: center;
}

.cardWelcome {
  color: white;
  font-size: 20px !important;
  font-family: "Google Sans" !important;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700 !important;
}

.btnArea {
  margin-bottom: 26px;
}

.cardExplication {
  color: white;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px !important;
}

.btnChangeStep {
  height: 40px !important;
  background-color: white !important;
  color: #5f6368 !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  text-transform: none !important;
  border-radius: 20px !important;
}