.SliderHorizontal{
    width: 200%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: left 0.5s;
    left:0;
    >div{
        // width: 50%;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: block;
       
    }
}