
.Scrollbar{
    scrollbar-width: thin;
}
.Scrollbar::-webkit-Scrollbar {
    background-color: transparent;
    width: 5px;
    height:5px;
    

}

/* background of the Scrollbar except button or resizer */
.Scrollbar::-webkit-Scrollbar-track {
    background-color: transparent;
}
.Scrollbar::-webkit-Scrollbar-track:hover {
    background-color: transparent;
}

/* Scrollbar itself */
.Scrollbar:hover::-webkit-Scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 5px;
}
.Scrollbar:hover::-webkit-Scrollbar-thumb:hover {
    background-color: #dfdfdf;
}
.Scrollbar::-webkit-Scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px;
}
.Scrollbar::-webkit-Scrollbar-thumb:hover {
    background-color: #dfdfdf;
}
/* set button(top and bottom of the Scrollbar) */
.Scrollbar::-webkit-Scrollbar-button {
    display: none;
}

/* div box */
.Scrollbar {
    // min-width: 150px;
    height:100%;
    width:100%;
    overflow-y: auto!important;
    overflow-x: auto!important;
    max-height: inherit;
}
