.allContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.infoContainer {
  background-color: #ffffff;
  height: calc(100% - 70px);
}

.bottomContainer {
  background-color: #f8f8fa;
  height: 70px;
  justify-content: end;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.transformCardSpecial {
  box-shadow: none !important;
  background-color: transparent !important;
  .limitSpace {
    margin: auto;
    margin-top: 48px;
    display: flex;
    justify-content: center;
  }
}

.cardTitleTextWarning {
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #424242 !important;
}

.cardDescriptionWarning {
  margin-top: 2px !important;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  line-height: 1.3 !important;
  letter-spacing: 0.01071em !important;
  color: #565b6b;
}

.centerText {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
  text-align: center;
}

.btnFlat {
  box-shadow: none !important;
  text-transform: none !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  width: 100%;
  border-radius: 6px !important;
  background-color: #d71a36 !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}
