.zoomBtn {
  position: fixed !important;
  bottom: 14px;
  z-index: 3;
  right: 14px;
}

.zoomBtnFab {
  height: 60px !important;
  width: 60px !important;
  color:white !important;
  background-color: #D0051E !important;
  z-index: 3;
  box-shadow: none !important;
}

.zoomBtnMenu {
  position: fixed;
  text-align: right;
  height: auto;
  width: auto;
  right: 22px;
  bottom: 60px;
  display: none;
}

.showZoom {
  display: block;
}

.twoBtnSpace {
  top: -128px;
}

.threeBtnSpace {
  top: -184px;
}

.zoomBtnMenu li {
  display: inline-block;
}

.zoomBtnMenuItems {
  width: 176px !important;
  height: 46px !important;
  margin-bottom: 10px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.zoomBtnMenuInternalBtn {
  width: 46px !important;
  height: 46px !important;
  background-color: #e9334e!important;
  margin-left: 10px !important;
  box-shadow: none !important;
}

.scaleTransition {
  transition: transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
}

.scaleTransition.scaleOut {
  transform: scale(0);
  transition: transform 0.2s !important;
}

.scaleTransition.scaleIn {
  transform: scale(1);
}

.fabPrice {
  z-index: 2;
  position: fixed !important;
  bottom: 25px;
  right: 66px;
  height: 38px;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  border: 2px solid #e9334e;
  background-color: #e9334e;
  padding-left: 10px;
  padding-right: 13px;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  line-height: 2.2;
  color: white;
}

.iconWhats {
  color: white !important;
}

.zoomBtnMenuInternalBtnWA {
  width: 46px !important;
  height: 46px !important;
  background-color: #0dc143!important;
  margin-left: 10px !important;
}

.zoomBtnMenuInternalBtnLI {
  width: 46px !important;
  height: 46px !important;
  background-color: #eba613!important;
  margin-left: 10px !important;
}