canvas {
  width: 100% !important;
  height: auto !important;
}

.transformCard {
  box-shadow: none !important;
  background-color: transparent !important;
  .limitSpace {
    max-width: 250px !important;
    margin: auto;
  }
}

.cardImgTop {
  width: 100%;
}

.centerText {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
  text-align: center;
}

.cardTitleText {
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #424242 !important;
}

.cardDescription {
  margin-top: 8px !important;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  line-height: 1.3 !important;
  letter-spacing: 0.01071em !important;
  color: #565B6B;
}

.cardActionsBtns {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 0px !important;
  padding-bottom: 12px !important;
}

.btnFlat {
  box-shadow: none !important;
  text-transform: none !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  width: 100%;
  border: 1px solid #d71a36 !important;
}
