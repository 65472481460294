.transformCard {
    box-shadow: none !important;
    border-bottom: 1px solid #d6d6d6 !important;
    border-radius: 0px !important;
}

.transformCardSpecial {
    box-shadow: none !important;
    border-top: 1px solid #d6d6d6 !important;
    border-bottom: 1px solid #d6d6d6 !important;
    border-radius: 0px !important;
  }

.specialContent {
    padding-top: 12px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-bottom: 8px !important;
}

.cardTitleWapper{
    width: 100%;
    height: auto;
    display: flex;
}

.cardTitle {
    width: calc(100% - 96px);
    align-items: center;
}

.cardTitleText {
    line-height: 1.2 !important;
    font-family: 'Google Sans' !important;
    font-weight: 700 !important;
    color: #424242 !important;
}

.cardDescription {
    margin-top: 2px;
    position: relative;
    font-size: 14px !important;
    font-family: "Google Sans" !important;
    line-height: 1.3 !important;
    letter-spacing: 0.01071em !important;
    color: #565B6B;
}

.cardNotes {
    margin-top: 4px;
    position: relative;
    font-size: 13px !important;
    font-family: "Google Sans" !important;
    line-height: 1.3 !important;
    letter-spacing: 0.01071em !important;
    color: #565B6B;
    background-color: #f8f8f8;
    margin-bottom: 2px;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
}

.cardAvatar {
    width: 104px;
    height: 96px;
    padding-left: 8px;
}
  
.avatarRounder {
    background: #c0bfbf;
    border-radius: 4px;
    color: rgba(255, 255, 255, .95);
    line-height: 1.25;
    margin: 0;
    position: relative;
    vertical-align: middle;
    float: left;
    font-size: 38px !important;
    height: 96px !important;
    width: 96px !important;
    box-shadow: 0 0.25rem 0.8rem rgba(48,55,66,.15) !important;
  }
  
.avatarRounder[data-initial]::before {
    color: currentColor;
    content: attr(data-initial);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-family: "Google Sans";
}
  
.avatarRounder img {
    border-radius: 4px;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
    object-fit: cover;
}

.cardActionsBtns {
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-top: 0px !important;
    padding-bottom: 12px !important;
}

.cardActionsNotes {
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-top: 0px !important;
    padding-bottom: 10px !important;
}

.currencyColor {
    color: #02bf77 !important;
    font-family: 'Google Sans' !important;
    font-weight: 700 !important;
}

.fabBtn {
    position: fixed !important;
    bottom: 14px;
    z-index: 3;
    right: 14px;
    box-shadow: none !important;
    height: 60px !important;
    width: 60px !important;
    color:white !important;
    background-color: #9e0011 !important;
}

.fabAddPrice {
    z-index: 2;
    position: fixed !important;
    bottom: 25px;
    right: 66px;
    height: 38px;
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px;
    border: 2px solid #d71a36;
    background-color: #d71a36;
    padding-left: 10px;
    padding-right: 16px;
    font-family: 'Google Sans' !important;
    font-weight: 700 !important;
    line-height: 2.2;
    color: white;
}

.comentaryText {
    color: #CB2128;
    text-decoration: underline;
}

.cantColor {
    color: #d71a36;
}

.xColor {
    color: #bebebe;
}

.btnFlat {
    box-shadow: none !important;
    color:#d71a36 !important;
    background-color: white !important;
    border: 1.5px solid #d71a36 !important;
    text-transform: none !important;
    font-family: 'Google Sans' !important;
    font-weight: 700 !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.iconBig {
    font-size: 32px !important;
}
.btnPay {
    box-shadow: none !important;
    text-transform: none !important;
    font-family: 'Google Sans' !important;
    font-weight: 700 !important;
    width: 100%;
    border: 1px solid #3483fa !important;
    background: #3483fa!important;
    margin-top:20px!important;
    display: block;
  }
  .cardTotal {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
    word-wrap: break-word;
    flex: 1 0;
    margin-top: 20px;
  }
  
  .cardTotalFooter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 4px;
    padding-top: 4px;
  }
  
  .cardTotalFooter .footerSection {
    align-items: center;
    display: flex;
    display: -ms-flexbox;
    -ms-flex: 1 0 0;
    flex: 1 0 0; 
    -ms-flex-align: center;
  }
  
  .cardTotalFooter .footerSection:not(:first-child):last-child {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  
  .cardTotalFooter .footerCenter {
    align-items: center;
    display: flex;
    display: -ms-flexbox;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; 
    -ms-flex-align: center;
    font-size: 18px;
    font-weight: 600;
    font-family: "Google Sans";
    color: #424242;
  }