.allContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.topContainer {
  background-color: white;
}

.specialsubtitle {
  font-family: "Google Sans" !important;
  font-size: 16px !important;
  padding-top: 22px;
  text-decoration: underline;
}

.specialsubtitlepay {
  line-height: 1.2 !important;
  font-family: 'Google Sans' !important;
  font-weight: 400 !important;
  color: #989898 !important;
  font-size: 14px !important;
  padding-top: 12px;
  padding-bottom: 12px;
}

.titleCat {
  color: #d71a36 !important;
  font-family: "Google Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  display: block;
  margin: 16px;
  width: calc(100% - 70px);
}

.infoContainer {
  background-color: #ffffff;
  height: calc(100% - 128px);
}

.btnFlatSelect {
  text-transform: none !important;
  font-family: "Google Sans" !important;
  font-weight: 600 !important;
  min-width: 140px;
}

.formMargins {
  margin-left: 22px;
  margin-right: 22px;
  .btnsModalityContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 400px;
  }
}

.textDescription {
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  line-height: 1.3 !important;
  letter-spacing: 0.01071em !important;
  color: #565b6b;
}

.textField {
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkSeparator {
  margin-top: 15px;
}

.useFont {
  font-family: "Google Sans" !important;
}
.botonera {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 20px !important;
  }
}

.bottomContainer {
  background-color: #f8f8fa;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.btnFlat {
  box-shadow: none !important;
  text-transform: none !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  width: 100%;
  border-radius: 6px !important;
  background-color: #d71a36 !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  width: 110px;
}

.checkSeparatorSpace {
  margin-bottom: 15px;
}

.togglebtnfullwith {
  width: 100% !important;
}

.toggletext {
  text-transform: none !important;
  font-family: "Google Sans" !important;
  font-weight: 600 !important;
  padding: 6px !important;
}
