.transformCard {
  box-shadow: none !important;
  background-color: transparent !important;
}

.productCenter{
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 10px;
}

.avatarRounder {
  background: #c0bfbf;
  border-radius: 10px;
  color: rgba(255, 255, 255, .95);
  line-height: 1.25;
  margin: 0;
  position: relative;
  vertical-align: middle;
  float: left;
  font-size: 38px !important;
  width: 100% !important;
  height: 180px;
  box-shadow: 0 0.25rem 0.8rem rgba(48,55,66,.15) !important;
}

.avatarRounder[data-initial]::before {
  color: currentColor;
  content: attr(data-initial);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-family: "Google Sans";
}

.avatarRounder img {
  border-radius: 10px;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
  object-fit: cover;
}

.specialContent {
  padding-top: 192px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 8px !important;
}

.specialContent2 {
  padding-top: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 8px !important;
}

.cardTitleText {
  line-height: 1.2 !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  color: #424242 !important;
}

.cardDescription {
  margin-top: 8px !important;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  line-height: 1.3 !important;
  letter-spacing: 0.01071em !important;
  color: #565B6B;
}

.cardIndividualPrice {
  margin-top: 8px !important;
  line-height: 1.3 !important;
  letter-spacing: 0.01071em !important;
  color: #02bf77 !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  padding-bottom: 12px;
}

.specialContent4 {
  padding-top: 12px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 8px !important;
}

.cardTitleWapper{
  width: 100%;
  height: auto;
  display: flex;
}

.btnRounderFlat {
  box-shadow: none !important;
  color:#d71a36 !important;
  background-color: white !important;
}

.zoneCant{
  color: #424242!important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  min-width: 26px;
  text-align: center;
}

.currencyColor {
  color: #02bf77 !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  width: 100%;
  text-align: right;
  padding-top: 9px;
}

.specialContent3 {
  padding-top: 0px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 8px !important;
}

.cardComentary {
  align-items: center;
  margin-top: -16px;
}

.cardActionsBtns {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 0px !important;
  padding-bottom: 12px !important;
}

.btnFlat {
  box-shadow: none !important;
  text-transform: none !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  width: 100%;
  border-radius: 6px !important;
  background-color: #d71a36 !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.textField {
  width: 100%;
}

.spacerBottom{
  margin-bottom: 60px;
}

.allContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.infoContainer {
  height: calc(100% - 70px);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 10px;
  background-color: white;
}
.bottomContainer {
  background-color: #F8F8FA;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.spaceBtnPlus {
  margin-left: 20px;
}