.infoArea {
  margin-bottom: 26px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.infoUser {
  max-width: 500px;
  width: 90% !important;
  min-height: 50px;
  background-color: #fff;
  border-radius: 8px !important;
  display: grid;
  grid-template-columns: 50px 1fr;
  padding: 15px 20px;
  gap: 10px;
}
.avatarRounder {
  background: #c0bfbf;
  border-radius: 25px;
  color: rgba(255, 255, 255, 0.95);
  line-height: 1.25;
  margin: 0;

  font-size: 20px !important;
  height: 50px !important;
  width: 50px !important;
  box-shadow: 0 0.25rem 0.8rem rgba(48, 55, 66, 0.15) !important;
  img {
    border-radius: 50%;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
    object-fit: cover;
  }
}

.user {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .markText {
    width: 100%;
    font-family: "Google Sans" !important;
    font-weight: 700 !important;
    font-size: 14px;
  }

  .normalText {
    width: 100%;
    font-family: "Google Sans" !important;
    color: #666;
    font-size: 14px;
  }
}
.btnNormal {
  box-shadow: none !important;
  border-radius: 8px !important;
  height: 50px !important;
  width: 300px !important;
  background-color: #d71a36 !important;
  color: white !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 14px !important;
  padding: 6px 8px !important;
}
a.otheAcount {
  grid-column: 1/3;
  width: 100%;
  display: flex;
  color: #00a1ff;
  text-align: center;
  font-family: "Google Sans" !important;
  justify-content: center;
  margin: 10px 0;
  font-size: 14px;
}

.transformCardWarning {
  box-shadow: none !important;
  background-color: transparent !important;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.centerText {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
  text-align: center;
}

.cardImgPosition {
  margin-bottom: 40px;
  width: 60%;
}

.cardExplication {
  color: white;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px !important;
}

.cardWelcome {
  color: white;
  font-size: 20px !important;
  font-family: "Google Sans" !important;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700 !important;
}




