.allContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bottomContainer {
  background-color: #f8f8fa;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  .priceData {
    display: flex;
  }
}

.btnFlat {
  box-shadow: none !important;
  text-transform: none !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  width: 100%;
  border-radius: 6px !important;
  background-color: #d71a36 !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  width: 110px;
}

.priceText {
  color: #424242;
  font-size: 16px;
  font-weight: 600;
  font-family: "Google Sans";
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  .priceColor {
    color: #02bf77 !important;
    font-size: 20px !important;
  }
}

.titleCat {
  color: #d71a36 !important;
  font-family: "Google Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  display: block;
  margin: 16px;
  width: calc(100% - 70px);
}

.transformCard {
  margin: 12px;
  box-shadow: 0 0.16rem 1rem rgba(48,55,66,.15) !important;
  border-radius: 10px !important;
  background-color: #fff !important;
}

.specialContent {
  padding: 12px !important;
}

.cardTitleWapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.cardTitleExpand {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardTitleText {
  line-height: 1.2 !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  color: #424242 !important;
  font-size: 16px !important;
}

.cantColor {
  color: RGB(215, 26, 54);
}

.xColor {
  color: #bebebe;
}

.btnRounderDel {
  background-color: white !important;
  box-shadow: none !important;
  color: #c62828 !important;
  border: 2px solid rgba(139, 73, 73, 0.7) !important;
}

.cardActionsNotes {
  padding: 0px !important;
}

.cardNotes {
  font-size: 14px !important;
  font-family: "Google Sans" !important;
  line-height: 1.3 !important;
  letter-spacing: 0.01071em !important;
  color: #818181;
  width: 100%;
}

.currencyColor {
  color: #02bf77 !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  position: relative;
}

.transformCardSpecial {
  box-shadow: none !important;
  background-color: transparent !important;
  .limitSpace {
    max-width: 250px !important;
    margin: auto;
    margin-top: 48px;
  }
}

.cardTitleTextWarning {
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #424242 !important;
}

.cardDescriptionWarning {
  margin-top: 2px !important;
  font-size: 16px !important;
  font-family: "Google Sans" !important;
  line-height: 1.3 !important;
  letter-spacing: 0.01071em !important;
  color: #565b6b;
}

.centerText {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 24px;
  text-align: center;
}


.infoContainer {
  background-color: #ffffff;
  height: calc(100% - 128px);
}

.divider {
  border-top: 0.05rem solid #f1f3f5;
  list-style-type: none;
  margin-top: 6px;
  margin-bottom: 6px;
}

.btnRounderBorder {
  box-shadow: none !important;
  border: 2px solid rgba(102, 102, 102, 0.7) !important;
  background-color: #f8f8fa !important;
  width: 36px !important;
  height: 36px !important;
}

.btnRounderFlat {
  box-shadow: none !important;
  color: #d71a36 !important;
  background-color: #f8f8fa !important;
}

.btnRounderFlat2 {
  box-shadow: none !important;
  color: #d71a36 !important;
  background-color: white !important;
}

.addIcons {
  justify-content: center;
  align-items: center;
  display: flex;
}
.spaceBtnPlus {
  // margin-left: 20px;
  margin-right: 20px;
}

.zoneCant {
  color: #424242 !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  min-width: 26px;
  text-align: center;
}
