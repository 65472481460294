.allContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.topContainer {
  background-color: white;
}

.titleCat {
  color: #d71a36 !important;
  font-family: "Google Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  display: block;
  margin: 16px;
  width: calc(100% - 70px);
}

.infoContainer {
  background-color: #ffffff;
  height: calc(100% - 128px);
}

.bottomContainer {
  background-color: #f8f8fa;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.btnFlat {
  box-shadow: none !important;
  text-transform: none !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  width: 100%;
  border-radius: 6px !important;
  background-color: #d71a36 !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  width: 140px;
}

.transformCard {
  box-shadow: none !important;
}

.specialContent {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 0px !important;
  padding-bottom: 10px !important;
}

.cardTitleWapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.divider {
  border-top: 0.05rem solid #f1f3f5;
  list-style-type: none;
}

.cardTitleText {
  line-height: 1.2 !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  color: #424242 !important;
  font-size: 14px !important;
}

.cardTitleText2 {
  line-height: 1.2 !important;
  font-family: 'Google Sans' !important;
  font-weight: 400 !important;
  color: #989898 !important;
  font-size: 14px !important;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
}

.cardTitleTextPrice {
  line-height: 1.2 !important;
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  color: #15974f !important;
  font-size: 16px !important;
}

.cantColor {
  color: RGB(215,26,54);
}

.dataColor {
  color: #424242;
  font-weight: 700 !important;
}

.xColor {
  color: #bebebe;
}

.currencyColor {
  color: #02bf77 !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  position: relative;
  margin-left: 10px !important;
  white-space: nowrap;
}

.currencyColor2 {
  color: #15974f !important;
  font-family: "Google Sans" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  position: relative;
  margin-left: 10px !important;
  white-space: nowrap;
}

.cardActionsNotes {
  padding: 0px !important;
}

.cardNotes {
  font-size: 14px !important;
  font-family: "Google Sans" !important;
  line-height: 1.3 !important;
  letter-spacing: 0.01071em !important;
  color: #818181;
  width: 100%;
  margin-top: 6px !important;
}

.specialsubtitle {
  font-family: "Google Sans" !important;
  font-size: 16px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: underline;
}

.formMargins {
  margin-left: 22px;
  margin-right: 22px;
}