.Preload{
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #D0051E;
  z-index: 9999;
}

@-webkit-keyframes rotateLog{
  0%   { -webkit-transform: rotate(0deg); }
  100%   { -webkit-transform: rotate(360deg); }
}
@-moz-keyframes rotateLog{
  0%   { -moz-transform: rotate(0deg); }
  100%   { -moz-transform: rotate(360deg); }
}

@keyframes rotateLog{
  0%   { transform: rotate(0deg); }
  100%   { transform: rotate(360deg); }
}
@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  animation: $animation; 
}
@mixin transform-translate($left,$top){
  transform: translate($left, $top);
  -webkit-transform: translate($left, $top);
  -ms-transform: translate($left, $top);
}
.center{
  position: absolute;
  top: 50%;
  left:50%;
  @include transform-translate(-50%,-50%);
}

.preloaderContent{
  @extend .center;
  width: 32px;
  height: 30px;
  display: block;
  @include animation(rotateLog 2s infinite linear);
  background: url("../img/ico_logo.png")
}