.floatPrice {
  position: fixed !important;
  bottom: 13px;
  z-index: 3;
  left: 14px;
  background-color: #e2f3c8;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

.fabPrice {
  font-family: 'Google Sans' !important;
  font-weight: 700 !important;
  color: #5b8f49 !important;
}

.fabCant {
  font-family: 'Google Sans' !important;
  font-weight: 500 !important;
  font-size: 12px;
  color: #6db156 !important;
}