.drawerWapper{
  //height: 100vh;
  height: 100%;
}

.drawerHeader {
  height: 58px;
  background-color: #F8F8FA;
  align-items: center;
  display: flex;
}

.drawerContainer {
  width: 100%;
  height: calc(100% - 112px);
  overflow-y: auto;
  padding-bottom: 20px;
  .headerLocal {
    font-family: "Google Sans";
    font-weight: 700;
    font-size: 18px;
    text-align: center;
  }
  .titleCat{
    color: #d71a36 !important;
    font-family: "Google Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    display: block;
    margin: 16px;
    position: relative !important;
  }
  .titleCat:before {
    content: "";
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 1px;
    width: 100%;
    border-bottom: .1rem solid rgba(215, 26, 54, 0.6);
  }
}

.drawerFooter {
  height: 54px;
  width: 100%;
  background-color: #F8F8FA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .logoCenter{
    text-align: center;
  }
}

.toolbarLogo {
  width: 80px !important;
  position: relative;
  top: 4px;
}

.mozoLink {
  color: #d71a36 !important;
  font-family: "Google Sans";
  font-weight: 600;
  text-decoration: underline !important;
  text-align: center;
  font-size: 12px;
}

.startsPos {
  display: flex;
  flex-direction: column;
  align-items: center;
  .startDescription {
    color: #eba613 !important;
    font-family: "Google Sans";
    font-weight: 600;
    font-size: 14px;   
  }
}

.newHeader {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  background-color: #F8F8FA;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  .columData {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 12px; 
  }
  .cardAvatar {
    width: 78px;
    height: 78px;
    margin-right: 12px;
  }
  
  .avatarRounder {
    background: #F8F8FA;
    border-radius: 39px;
    color: #ACACAD;
    line-height: 1.25;
    margin: 0;
    position: relative;
    vertical-align: middle;
    float: left;
    font-size: 32px !important;
    height: 78px !important;
    width: 78px !important;
  }
  
  .avatarRounder[data-initial]::before {
    color: currentColor;
    content: attr(data-initial);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-family: "Google Sans";
  }
  
  .avatarRounder img {
    border-radius: 39px;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
    object-fit: cover;
  }
}

.btnRounderBorder {
  box-shadow: none !important;
  background-color: white !important;
  position: absolute !important;
  z-index: 9;
  right: 12px;
  top: 12px;
  height: 36px !important;
  width: 36px !important;
}

.headerAbout {
  font-family: "Google Sans";
  font-size: 16px;
  letter-spacing: 0.0075em;
  line-height: 1.5;
  margin-left: 16px;
  font-weight: 400;
}